import React, { useState, useEffect } from 'react';
import "user/courseDetail/component/CategoryBar.css"

function CategoryBar({ onScrollToImage, scrollToProject, content, isProjectPublic }) {
    
    const [activeCategory, setActiveCategory] = useState(0); // 현재 활성화된 카테고리

    useEffect(() => {
        //스크롤 위치에 따라 활성 카테고리를 업데이트하는 함수
        const handleScroll = () => {
            const imageElements = document.querySelectorAll('.content-image-container');
            const projectSection = document.querySelector('.project-section');
            const stickyHeader = document.querySelector('.sticky-header');
            
            // 스크롤 위치 계산
            const headerHeight = stickyHeader?.offsetHeight || 0;
            const scrollPosition = window.scrollY + headerHeight;

            let lastMatchedIndex = -1;  // 현재 스크롤 위치의 섹션 인덱스
            let lastVisibleIndex = -1;  // 마지막으로 보이는 카테고리 인덱스

            // 각 이미지 섹션 확인
            imageElements.forEach((element, index) => {
                const elementTop = element.offsetTop;
                
                if (content[index].courseContentName) {
                    lastVisibleIndex = index;  // 보이는 카테고리 갱신
                }
                
                // 현재 스크롤 위치에 해당하는 섹션 확인
                if (scrollPosition >= elementTop) {
                    lastMatchedIndex = index;  // 현재 스크롤 위치 갱신
                }
            });

            // 프로젝트 섹션이 있고 해당 위치에 도달했을 경우
            if (projectSection && isProjectPublic) {
                const projectTop = projectSection.offsetTop;
                if (scrollPosition >= projectTop) {
                    setActiveCategory('project');
                    return;
                }
            }

            // 활성 카테고리 업데이트
            if (lastMatchedIndex !== -1) {
                const isHiddenCategory = !content[lastMatchedIndex].courseContentName;
                setActiveCategory(isHiddenCategory ? lastVisibleIndex : lastMatchedIndex);
            }
        };

        // 스크롤 이벤트 설정
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // 초기 실행
        
        return () => window.removeEventListener('scroll', handleScroll);
    }, [content, isProjectPublic]);

    return (
        <nav className='category-bar'>
            <ul>
                {/* 카테고리 목록 */}
                {content.map((course, index) => (
                    course.courseContentName ? (
                        // 일반 카테고리
                        <li 
                            key={index} 
                            onClick={() => onScrollToImage(index)}
                            role="button"
                            tabIndex={0}
                            className={activeCategory === index ? 'active' : ''}
                        >
                            {course.courseContentName}
                        </li>
                    ) : (
                        // 숨김 카테고리
                        <li key={index} className="hidden-category" aria-hidden="true" />
                    )
                ))} 

                {/* 프로젝트 카테고리 */}
                {isProjectPublic && (
                    <li 
                        onClick={scrollToProject}
                        role="button"
                        tabIndex={0}
                        className={activeCategory === 'project' ? 'active' : ''}
                    >
                        프로젝트
                    </li>
                )}
            </ul>
        </nav>
    );
}

export default CategoryBar;